<template>
	<shutter-panel ref="shutterPanel" />
</template>

<script type="text/javascript">
import Shutter from "@/mixins/Shutter.js"
import ShutterSemence from "@/mixins/shutters-manager/Semence.js"

export default {
	name: 'StallionStockLocal',
	mixins: [Shutter, ShutterSemence],
	props: ['horse'],
	data () {
		return {
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		init_component() {
			this.setupStallionDetails(this.horse, null, true)
		}
	}
}

</script>